/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import Layout from 'components/layout/layout';
import { Link, PageProps } from 'gatsby';
import { useLocation } from '@reach/router';
import AliceCarousel from 'react-alice-carousel';
import arrow from 'images/ui/arrow_black.svg';
import Card from 'components/card/card';
import Button from 'components/ui/button';
import { data as sideBarData } from 'data/marketplace/sidebar';
import { useQuery } from 'react-query';
import Slider from 'react-slick';
import {
  fetchIntegration,
  fetchSimilarIntegrations,
  postMarketplaceVisit,
  postMarketplaceClickedInstall,
  fetchIntegrationCategory,
} from 'api/api';
import { formatIntegrationToCard, formatCategory } from 'helpers/integrationFormat';
import FullScreenLoading from 'components/ui/loading';
import SideBar from 'components/sideBar/sideBar';
import { IntegrationType, Integration, ImageData } from 'types/api';
import { IntegrationPage, FormattedIntegration } from 'types/custom';
import { formatIntegrations } from 'hooks/useFormattedIntegrations';
import InstalledApps from 'components/installedApps/installedApps';
import IntegrationCard from 'components/integrationCard/integrationCard';
import { GridBox } from 'pages/index';
import * as Styled from './styled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-alice-carousel/lib/alice-carousel.css';

interface CardContextProps {
  render: (arg: FormattedIntegration, arg2: Integration) => void;
}

export const CardContext: React.FC<CardContextProps> = ({ render }) => {
  const location = useLocation();
  const currentIntegration = location.pathname.split('/')[2];

  const { isLoading, data } = useQuery(
    `integration-${currentIntegration}`,
    () => fetchIntegration(currentIntegration),
    {
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 24,
    },
  );

  const similarIntegrationData = useQuery(
    `integration-${currentIntegration}-similar`,
    () => fetchSimilarIntegrations(currentIntegration),
    {
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 24,
    },
  );

  const [showMoreCards, setShowMoreCards] = useState(false);
  const fixedButton = useRef<HTMLDivElement>(null);

  const listenToScroll = useCallback(() => {
    const position = window.pageYOffset;
    if (fixedButton.current) {
      if (position > 400) {
        fixedButton.current.classList.add('active');
      } else {
        fixedButton.current.classList.remove('active');
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    setShowMoreCards(true);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, [listenToScroll]);

  useEffect(() => {
    postMarketplaceVisit();
    window.parent.postMessage(window.location.pathname, `${process.env.GATSBY_APP_URL}`);
  }, []);

  const integrations = useQuery(`fetch-integrations`, () => fetchIntegrationCategory(''), {
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
  });

  const formattedIntegration = integrations.data && formatIntegrations(integrations.data);

  const card = data && formatIntegrationToCard(data);

  const moreCards =
    similarIntegrationData?.data?.integrations &&
    similarIntegrationData.data.integrations.map((item: IntegrationType) =>
      formatIntegrationToCard(item),
    );

  const renderMoreCards = (arr: FormattedIntegration[]) =>
    arr.map((item: FormattedIntegration) => <Card key={item.link} {...item} />);

  const checkCategory = (category: string) => {
    const [getCurrentCat] = sideBarData.flatMap(({ list }) =>
      list.filter(({ title }) => category === title),
    );
    return getCurrentCat.link;
  };

  const images = data ? data.images.filter((item: ImageData) => item.type !== 'icon') : [];

  const sliderSettings = (photos: Array<ImageData>) => {
    const settings = {
      customPaging: (i: number) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a style={{ backgroundImage: `url(${photos[i].original_url})` }} />
      ),
      dots: photos.length > 1,
      dotsClass: 'slick-dots',
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return settings;
  };

  const handleInstallNow = (e: React.MouseEvent<HTMLElement>, link: string) => {
    e.stopPropagation();
    window.parent.postMessage(
      `${process.env.GATSBY_APP_URL}${link}`,
      `${process.env.GATSBY_APP_URL}`,
    );
  };

  const handleIntegrationBtnClick = () => postMarketplaceClickedInstall();

  return isLoading ||
    similarIntegrationData.isLoading ||
    !data ||
    !similarIntegrationData.data ||
    !card ||
    !formattedIntegration ? (
    <FullScreenLoading fullScreen />
  ) : (
    <Layout
      canonical={location.pathname}
      metaTitle={`${` ${card.title} - ${formatCategory(card.activeCategory)} | Integrations`}`}
      metaDescription={card.short_description}
      // image={card.cover}
    >
      <Styled.Section>
        <SideBar
          activeCategory={formatCategory(card?.activeCategory)}
          integrations={formattedIntegration}
        />
        <GridBox style={{ display: 'block' }}>
          <InstalledApps />
          <Styled.ExtendGridBox>
            <div className="breadcrumbs">
              <p>
                <Link to="/">Integrations</Link>
              </p>
              <img src={arrow} alt="arrow" title="Arrow" />
              <p>
                <Link to={`/integrations/${card?.activeCategory.toLowerCase().replace(' ', '-')}/`}>
                  {formatCategory(card?.activeCategory)}
                </Link>
              </p>
              <img src={arrow} alt="arrow" title="Arrow" />
              <p>
                <Link to={location.pathname}>
                  <strong>{card?.title}</strong>
                </Link>
              </p>
            </div>
            <Styled.CardColumn>
              <div className="card-box">
                {!isLoading && <IntegrationCard {...card} linkTag="a" />}
                <div className="card-footer">
                  <p className="categories">
                    <strong>Categories</strong>
                  </p>
                  <Styled.Categories>
                    <div>
                      {card &&
                        card?.categories &&
                        card?.categories.map((cat: string) => (
                          <Link
                            className="category"
                            key={cat}
                            to={checkCategory(formatCategory(cat))}
                          >
                            <span>{formatCategory(cat)}</span>
                          </Link>
                        ))}
                    </div>
                    <p className="madeby">
                      Made by: <strong>{card?.madeBy}</strong>
                    </p>
                  </Styled.Categories>
                </div>
              </div>
            </Styled.CardColumn>
            <Styled.ContentStyles>
              {!isLoading && (
                <>
                  {images.length > 0 && (
                    <Slider
                      {...sliderSettings(images)}
                      className={images.length > 1 ? '' : 'without-dots'}
                    >
                      {images.map((item: ImageData) => (
                        <div key={item.image_id}>
                          <img
                            src={item.original_url}
                            alt="BigCommerce Integration"
                            title={item?.title || item?.integration_id || ''}
                          />
                        </div>
                      ))}
                    </Slider>
                  )}
                  {render(card, data)}
                </>
              )}
            </Styled.ContentStyles>
          </Styled.ExtendGridBox>
          <Styled.MoreApps>
            <h2>Discover more apps</h2>
            <div className="card-box">
              {showMoreCards && moreCards && renderMoreCards(moreCards).slice(0, 3)}
            </div>
            <AliceCarousel disableButtonsControls infinite>
              {moreCards && renderMoreCards(moreCards)}
            </AliceCarousel>
          </Styled.MoreApps>
        </GridBox>
      </Styled.Section>

      <Styled.FixedButton ref={fixedButton}>
        {!isLoading &&
          (data.install_url && data.install_url.replace('$WEBAPP_URL', '') ? (
            <Button
              href={data.tutorial_url.replace('$WEBSITE_URL', '')}
              onClick={(e) => {
                handleIntegrationBtnClick();
                handleInstallNow(e, data.install_url.replace('$WEBAPP_URL', ''));
              }}
            >
              Install now
            </Button>
          ) : (
            <Button
              href={data.tutorial_url.replace('$WEBSITE_URL', '')}
              onClick={handleIntegrationBtnClick}
            >
              Install now
            </Button>
          ))}
      </Styled.FixedButton>
    </Layout>
  );
};

const withIntegrationCardContext =
  <T extends IntegrationPage & PageProps>(
    Component: React.ComponentType<T>,
  ): React.FC<T & IntegrationPage> =>
  (props: IntegrationPage) =>
    (
      <CardContext
        render={(card, data) => <Component {...(props as T)} card={card} integrationData={data} />}
      />
    );

export default withIntegrationCardContext;
