import React from 'react';
import integrationPage from 'hoc/integrationPage';
import { IntegrationPage as Props } from 'types/custom';

const IntegrationPage: React.FC<Props> = ({ integrationData }) => (
  <>
    <div className="section">
      <h2>Overview</h2>
      <p>{integrationData.description}</p>
    </div>
    <div className="section">
      <h2>Benefits</h2>
      <ul className="checkmarks">
        {integrationData.benefits.map(({ benefit }) => (
          <li key={benefit}>{benefit}</li>
        ))}
      </ul>
    </div>
    <h2>How to install</h2>
    <p>
      Just copy and paste a piece of code. See a detailed tutorial in our{' '}
      <a href="/help/how-install-on-bigcommerce/">help center</a>.
    </p>
  </>
);

export default integrationPage(IntegrationPage);
