import styled from 'styled-components';

export const Content = styled.div`
  margin-bottom: 44px;
  display: flex;
  justify-content: space-between;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  & .photo {
    height: 100px;
    width: 100px;
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;
  }
  & div.content {
    margin-left: 24px;
    & h1 {
      font-size: 36px;
      line-height: 48px;
    }
    & p {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 0;
      margin-top: 8px;
      max-width: 90%;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  & a:last-child {
    margin: 16px auto 0;
  }
`;
