import React from 'react';
import { CardProps } from 'types/custom';
import CustomLink from 'components/ui/link';
import Button from 'components/ui/button';
import { postMarketplaceClickedInstall } from 'api/api';
import * as Styled from './styled';

const IntegrationCard: React.FC<CardProps> = ({
  title,
  icon,
  link,
  integrationLink,
  linkTag,
  short_description,
  helpLink,
}) => {
  const handleInstallNow = (slug: string) => {
    window.parent.postMessage(
      `${process.env.GATSBY_APP_URL}${slug}`,
      `${process.env.GATSBY_APP_URL}`,
    );
  };

  const handleIntegrationBtnClick = () => postMarketplaceClickedInstall();

  return (
    <Styled.Content>
      <Styled.ContentContainer>
        <div className="photo" style={{ backgroundImage: `url(${icon})` }} />
        <div className="content">
          <h1>{title}</h1>
          <p>{short_description}</p>
        </div>
      </Styled.ContentContainer>

      <Styled.ButtonsContainer>
        {integrationLink ? (
          <Button
            href={`${process.env.GATSBY_APP_URL}${integrationLink}`}
            className={!integrationLink && link ? 'btn-only' : undefined}
            onClick={(e) => {
              e.stopPropagation();
              handleIntegrationBtnClick();
              handleInstallNow(integrationLink);
            }}
            style={{ minWidth: 205 }}
          >
            Install now
          </Button>
        ) : (
          <Button
            href={integrationLink ? `${process.env.GATSBY_APP_URL}${integrationLink}` : helpLink}
            className={!integrationLink && link ? 'btn-only' : undefined}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleIntegrationBtnClick}
            style={{ minWidth: 205 }}
          >
            Install now
          </Button>
        )}
        {integrationLink && integrationLink.length > 0 ? (
          <CustomLink
            withArrow
            tag={linkTag || 'p'}
            href={linkTag ? helpLink : undefined}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </CustomLink>
        ) : (
          ''
        )}
      </Styled.ButtonsContainer>
    </Styled.Content>
  );
};

export default IntegrationCard;
